<template>
  <div class="p-20 bg-white rounded">
    <a-tabs
      v-model="activityKey"
      @change="change"
      hide-add
      @edit="edit"
      type="editable-card"
    >
      <a-tab-pane key="1" tab="全部活动" :closable="false">
        <StatusFilterSelect
          @change="getList('1')"
          v-model="status"
          :filters="filters"
          class="mb-20"
        />
        <a-row :gutter="20" v-if="list.length">
          <a-col
            class="mb-20 hover:shadow"
            :span="24"
            v-for="item in list"
            :key="item.id"
          >
            <activity-card :dataSource="item" @click.native="add(item)">
              <a-popconfirm
                slot="opt"
                title="是否删除该活动库?"
                ok-text="删除"
                cancel-text="取消"
                @confirm="del(item.id)"
              >
                <span type="link" class="cursor-pointer">删除</span>
              </a-popconfirm>
            </activity-card>
          </a-col>
        </a-row>
        <list-empty v-else></list-empty>
        <div class="text-center" v-if="list.length && total > pagination.limit">
          <a-pagination
            :pageSize="pagination.limit"
            :total="total"
            v-model="current"
            @change="getOpActivityList"
          ></a-pagination>
        </div>
      </a-tab-pane>
      <a-tab-pane
        :tab="pane.name"
        v-for="(pane, index) in panes"
        :key="pane.key"
        :closable="true"
      >
        <div v-if="pane.edit" class="p-20 bg-white">
          <a-form-model
            :ref="pane.key"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 15 }"
            :model="pane.data"
            :rules="rules"
            :colon="false"
            labelAlign="left"
          >
            <a-form-model-item prop="Name" :wrapperCol="{ span: 9 }">
              <div slot="label" class="h-40 flex items-center">
                <span>活动名称</span>
              </div>

              <gf-input size="large" v-model="pane.data.Name"> </gf-input>
            </a-form-model-item>
            <a-form-model-item prop="time">
              <div slot="label" class="h-40 flex items-center">
                <span>活动时间</span>
              </div>
              <a-range-picker
                format="YYYY-MM-DD HH:mm"
                :showTime="true"
                :defaultValue="[]"
                size="large"
                v-model="pane.data.time"
                valueFormat="x"
                :placeholder="['开始时间', '结束时间']"
              />
            </a-form-model-item>
            <a-form-model-item prop="categoryId" :wrapperCol="{ span: 9 }">
              <div slot="label" class="h-40 flex items-center">
                <span>活动类型</span>
              </div>

              <a-select
                placeholder="请选择"
                size="large"
                v-model="pane.data.categoryId"
              >
                <a-select-option
                  v-for="option in ActivityType"
                  :key="option.ID"
                >
                  {{ option.Name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="address">
              <div slot="label" class="h-40 flex items-center">
                <span>活动地址</span>
              </div>

              <gf-input
                placeholder="请输入详细地址"
                size="large"
                v-model="pane.data.address"
              >
              </gf-input>
            </a-form-model-item>
            <a-form-model-item prop="Quota" :wrapperCol="{ span: 9 }">
              <div slot="label" class="h-40 flex items-center">
                <span>活动报名人数</span>
              </div>

              <a-input-number
                :min="1"
                class="w-full"
                size="large"
                v-model="pane.data.Quota"
              >
              </a-input-number>
            </a-form-model-item>
            <a-form-model-item prop="Desc">
              <div slot="label" class="h-40 flex items-center">
                <span>活动描述</span>
              </div>

              <gf-textarea
                placeholder="请在此输入活动内容描述"
                :required="true"
                prop="Desc"
                :max="2000"
                :maxLength="3000"
                v-model="pane.data.Desc"
                :rows="4"
              >
              </gf-textarea>
            </a-form-model-item>
            <a-form-model-item :required="true">
              <div slot="label" class="h-40 flex items-center">
                <span>活动发起人</span>
              </div>
              <div>
                <div
                  v-for="(item, index) in pane.data.Initiators"
                  :key="item.ID"
                  class="relative"
                >
                  <a-icon
                    v-if="pane.data.Initiators.length > 1"
                    @click="deleteCompitive(pane.data.Initiators, index)"
                    class="
                      align-middle
                      cursor-pointer
                      text-red-600
                      absolute
                      top-1/2
                      transform
                      -translate-y-1/2
                    "
                    style="right: -40px"
                    type="minus-circle"
                  />
                  <a-row :gutter="10">
                    <a-col :span="20">
                      <a-form-model-item
                        :prop="`Initiators.${index}.Name`"
                        :rules="{
                          required: true,
                          message: '请输入',
                          trigger: 'blur',
                        }"
                      >
                        <gf-input
                          placeholder="请输入公司名称"
                          v-model="item.Name"
                        ></gf-input>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="4">
                      <a-form-model-item
                        :prop="`Initiators.${index}.Logo`"
                        :rules="{
                          required: true,
                          message: '请输入',
                          trigger: 'blur',
                        }"
                      >
                        <picture-upload
                          :scale="{ w: 1, h: 1 }"
                          v-model="item.Logo"
                          :width="80"
                          :height="80"
                          accept=".png,.jpg,.jpeg"
                          :size="5"
                        ></picture-upload>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                </div>
                <div
                  @click="add1(pane.data.Initiators)"
                  class="
                    bg-white
                    h-40
                    border border-eee
                    rounded
                    text-center
                    leading-10
                  "
                >
                  添加
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item prop="Cover">
              <div slot="label" class="h-40 flex items-center">
                <span>活动封面</span>
              </div>
              <picture-upload
                v-model="pane.data.Cover"
                :width="338"
                :height="160"
                accept=".png,.jpg,.jpeg"
                :size="5"
                field="0"
              ></picture-upload>
            </a-form-model-item>
          </a-form-model>
          <div class="text-right">
            <a-button type="primary" @click="save(pane, index)">保存</a-button>
          </div>
        </div>
        <div v-else class="p-20 bg-white">
          <div class="p-20 bg-f9">
            <div class="relative flex">
              <div
                style="width: 400px; height: 225px"
                class="relative border border-eee rounded mr-20"
              >
                <img
                  v-if="pane.data.Cover"
                  :src="pane.data.Cover.url"
                  class="w-full h-full object-contain"
                  alt=""
                />
                <div
                  class="
                    absolute
                    top-20
                    bg-primary
                    rounded-tr-full rounded-br-full
                    text-xs text-white text-center
                    px-10
                  "
                  :class="{
                    'status-1': pane.data.status === 0,
                    'status-2': pane.data.status === 1,
                    'status-0': [2, 10].includes(pane.data.status),
                  }"
                  style="height: 26px; line-height: 26px"
                >
                  <span v-if="pane.data.status === 0">报名中</span>
                  <span v-else-if="pane.data.status === 1">进行中</span>
                  <span v-else-if="pane.data.status === 2">已结束</span>
                  <span v-else-if="pane.data.status === 10">已关闭</span>
                </div>
                <div
                  class="
                    px-10
                    absolute
                    right-0
                    bottom-0
                    text-white text-center text-xs
                  "
                  style="
                    height: 26px;
                    line-height: 26px;
                    background-color: rgba(0, 0, 0, 0.5);
                  "
                >
                  {{ pane.data.type.Name }}
                </div>
              </div>
              <div style="width: calc(100% - 400px)" class="pb-4 relative">
                <div class="font-bold mb-10">{{ pane.data.Name }}</div>
                <div class="mb-10 text-xs text-999 flex items-center">
                  <a-icon type="clock-circle" class="mr-10" />
                  {{ pane.data.time[0] | formatterTime("YYYY-MM-DD HH:mm") }} 至
                  {{ pane.data.time[1] | formatterTime("YYYY-MM-DD HH:mm") }}
                </div>
                <div class="text-xs text-999 flex items-center mb-20">
                  <a-icon type="environment" class="mr-10" />
                  <span>{{ pane.data.address }}</span>
                </div>
                <div
                  class="mb-40 flex justify-between items-center w-full text-xs"
                >
                  <div class="flex items-center">
                    <a-progress
                      style="width: 120px"
                      class="mr-10"
                      :show-info="false"
                      :percent="
                        pane.data.participants / pane.data.Quota < 1
                          ? Math.ceil(
                              (pane.data.participants / pane.data.Quota) * 100
                            )
                          : Math.floor(
                              (pane.data.participants / pane.data.Quota) * 100
                            )
                      "
                    />
                    <div>
                      名额已占{{
                        pane.data.participants / pane.data.Quota < 1
                          ? Math.ceil(
                              (pane.data.participants / pane.data.Quota) * 100
                            )
                          : Math.floor(
                              (pane.data.participants / pane.data.Quota) * 100
                            )
                      }}%
                    </div>
                  </div>
                </div>
                <div class="text-primary absolute bottom-0 right-0">
                  共{{ pane.data.participants }}/{{ pane.data.Quota }}人报名
                </div>
              </div>
            </div>
          </div>

          <div class="text-lg font-bold mt-40 mb-20">
            <span class="mr-10 text-primary align-middle">|</span>
            <span class="align-middle text-111 font-bold">活动描述</span>
          </div>

          <a-space class="w-full" :size="20" direction="vertical">
            <div
              v-for="(item, index) in pane.data.Desc.split('\n')"
              :key="index"
              style="word-wrap: break-word"
            >
              {{ item }}
            </div>
          </a-space>

          <div class="text-lg font-bold mt-40 mb-20">
            <span class="mr-10 text-primary align-middle">|</span>
            <span class="align-middle text-111 font-bold">活动发起人</span>
          </div>

          <a-space class="w-full" :size="20" direction="vertical">
            <div
              v-for="(item, index) in pane.data.Initiators"
              :key="index"
              style="word-wrap: break-word"
              class="flex items-center"
            >
              <div
                class="
                  mr-10
                  w-40
                  h-40
                  rounded-full
                  overflow-hidden
                  border border-eee
                "
              >
                <img
                  class="w-full h-full object-contain"
                  :src="item.Logo.url"
                  alt=""
                />
              </div>
              <div>{{ item.Name }}</div>
            </div>
          </a-space>

          <div class="text-lg font-bold mt-40 mb-20">
            <span class="mr-10 text-primary align-middle">|</span>
            <span class="align-middle text-111 font-bold">活动时间</span>
          </div>

          <div>
            <span v-if="pane.data.status === 10"
              >{{ pane.data.time[0] | formatterTime("YYYY-MM-DD HH:mm") }} 至
              {{ pane.data.time[1] | formatterTime("YYYY-MM-DD HH:mm") }}</span
            >
            <a-range-picker
              @ok="changeTime(pane.data, $event)"
              v-else
              format="YYYY-MM-DD HH:mm"
              :showTime="true"
              :defaultValue="[]"
              size="large"
              v-model="pane.data.time"
              valueFormat="x"
              :placeholder="['开始时间', '结束时间']"
            />
          </div>

          <div v-if="pane.data.type1 !== 'create'">
            <div class="text-lg font-bold mt-40 mb-20">
              <span class="mr-10 text-primary align-middle">|</span>
              <span class="align-middle text-111 font-bold"
                >报名情况（{{ participantCount }}个）</span
              >
            </div>

            <div>
              <div v-if="participant.length">
                <div>
                  <a-row :gutter="20">
                    <a-col
                      :span="4"
                      v-for="(item, index) in participant"
                      :key="index"
                      :class="{ 'mt-20': index > 5 }"
                    >
                      <div
                        class="
                          flex flex-col
                          items-center
                          border border-eee
                          p-10
                        "
                      >
                        <div class="w-40 h-40 mb-10">
                          <img
                            v-if="item.Profile"
                            class="w-full h-full object-contain"
                            :src="getFileUrl(item.Profile.ID)"
                            alt=""
                          />
                        </div>
                        <div class="ellipsis-2 mb-20 text-sm text-center">
                          {{ item.Name }}
                        </div>
                        <div class="text-xs text-999">查看详情</div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
                <div class="text-center mt-20">
                  <a-pagination
                    :pageSize="12"
                    :total="participantCount"
                    v-model="participantPage"
                    @change="getActivityParticipant(activityKey)"
                  ></a-pagination>
                </div>
              </div>

              <a-empty v-else></a-empty>
            </div>

            <div class="text-lg font-bold mt-40 mb-20" v-if="candidate.length">
              <span class="mr-10 text-primary align-middle">|</span>
              <span class="align-middle text-111 font-bold"
                >排队情况（{{ candidate.length }}个）</span
              >
            </div>

            <div v-if="candidate.length">
              <div>
                <a-row :gutter="20">
                  <a-col
                    :span="4"
                    v-for="(item, index) in candidate"
                    :key="index"
                  >
                    <div
                      class="flex flex-col items-center border border-eee p-10"
                    >
                      <div class="w-40 h-40 mb-10">
                        <img
                          class="w-full h-full object-contain"
                          :src="getFileUrl(item.Profile.ID)"
                          alt=""
                        />
                      </div>
                      <div class="ellipsis-2 mb-20 text-sm text-center">
                        {{ item.Name }}
                      </div>
                      <div class="text-xs text-999">查看详情</div>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div class="text-center mt-20">
                <a-pagination
                  :pageSize="16"
                  :total="candidateCount"
                  v-model="candidatePage"
                  @change="getActivityCandidate(pane.data.id)"
                ></a-pagination>
              </div>
            </div>
          </div>

          <div class="text-center mt-40">
            <a-button
              type="primary"
              v-if="pane.data.status !== 10"
              @click="doClose(pane.data.id)"
              >关闭活动</a-button
            >
          </div>
        </div>
      </a-tab-pane>
      <a-space slot="tabBarExtraContent" :size="20">
        <a-button
          type="primary"
          @click="
            add({ id: '4', name: '新增活动', type1: 'create', edit: true })
          "
        >
          新增活动
        </a-button>

        <a-input-search
          v-model="name"
          @search="getList('1')"
          :disabled="activityKey !== '1'"
        ></a-input-search>
      </a-space>
    </a-tabs>
    <a-modal
      v-model="visible"
      title="关闭原因"
      okText="关闭"
      cancelText="取消"
      :destroyOnClose="true"
      @ok="close"
    >
      <a-form-model :model="model" ref="container">
        <gf-form-item
          prop="Feedback"
          :rules="[
            {
              required: true,
              message: '请填写关闭原因',
              trigger: ['change', 'blur'],
            },
            { max: 300, message: '字数超出限制', trigger: ['change', 'blur'] },
          ]"
          style="margin: 0"
        >
          <gf-re-textarea :max="300" v-model="model.Feedback"></gf-re-textarea>
        </gf-form-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getOpActivityList,
  createActivity,
  delActivity,
  updateActivity,
  getActivityCandidate,
  getActivityParticipant,
  closeActivity,
} from "@/api/api/activity";
import { getFileUrl, getLocation } from "@/api/api/common";
export default {
  data() {
    return {
      status: 0,
      filters: [
        { value: 1, name: "报名中" },
        { value: 2, name: "进行中" },
        { value: 3, name: "已结束" },
        { value: 11, name: "已关闭" },
      ],
      activityKey: "1",
      list: [],
      name: "",
      total: 0,
      current: 1,
      pagination: {
        limit: 5,
        offset: 0,
      },
      panes: [],
      fieldHelp: "",
      fieldStatus: "",
      participant: [],
      candidate: [],
      participantCount: 0,
      candidateCount: 0,
      participantPage: 1,
      candidatePage: 1,
      model: {},
      visible: false,
      Location: [],
    };
  },
  created() {
    this.getList("1");
  },
  computed: {
    ActivityType() {
      return this.$store.state.options.ActivityType;
    },
    rules() {
      return {
        Name: [{ required: true, message: "请输入", trigger: "blur" }],
        time: [{ required: true, message: "请输入", trigger: "blur" }],
        author: [{ required: true, message: "请输入", trigger: "blur" }],
        categoryId: [{ required: true, message: "请选择", trigger: "blur" }],
        isRecommend: [{ required: true, message: "请选择", trigger: "blur" }],
        Cover: [{ required: true, message: "请输入", trigger: "blur" }],
        weight: [{ required: true, message: "请选择", trigger: "blur" }],
        Quota: [{ required: true, message: "请输入", trigger: "blur" }],
        Desc: [{ required: true, message: "请输入", trigger: "blur" }],
        address: [
          { required: "true", message: "请填写", trigger: "blur" },
          { validator: this.validateLocation, trigger: ["blur"] },
        ],
      };
    },
  },
  methods: {
    edit(key, action) {
      this[action](key);
    },
    add(data) {
      if (this.panes.length >= 4) {
        this.$message.warn("不能打开更多的tab");
        return;
      }
      if (this.panes.find((item) => item.key === data.id)) {
        this.activityKey = data.id;
        return;
      }
      if (!["1", "2", "3", "4"].includes(data.id)) {
        this.getActivityParticipant(data.id);
        this.getActivityCandidate(data.id);
      }
      this.panes.push({
        key: data.id,
        type: data.type1,
        data: data.type1 ? { Initiators: [{}] } : data,
        name: data.Name || data.name,
        edit: data.edit,
      });
      this.activityKey = data.id;
    },
    remove(key) {
      this.panes.forEach((item, index) => {
        if (key === item.key) {
          this.panes.splice(index, 1);
          if (index === 0) {
            this.activityKey = "1";
            this.getList("1");
          } else {
            this.activityKey = this.panes[index - 1].key;
          }
        }
      });
    },
    generateParams() {
      let temp = {
        status: this.status ? this.status - 1 : undefined,
        name: this.name,
        limit: this.pagination.limit,
        offset: this.pagination.limit * (this.current - 1),
      };
      return temp;
    },
    getOpActivityList() {
      const params = this.generateParams();
      getOpActivityList(params).then((data) => {
        this.total = data.count;
        this.list = data.rows || [];
      });
    },
    getList(e) {
      this.current = 1;
      if (e === "1") {
        this.getOpActivityList();
      } else if (e === "2") {
        this.getOpActivityList();
      } else if (e === "3") {
        this.getOpActivityList();
      } else if (e === "4") {
      } else {
        this.getActivityParticipant(e);
        this.getActivityCandidate(e);
      }
    },
    change(e) {
      this.name = "";
      this.current = 1;
      this.pagination = {
        limit: 5,
        offset: 0,
      };
      this.getList(e);
    },
    del(id) {
      delActivity(id).then(() => {
        this.$message.success("删除活动成功");
        this.getList(this.activityKey);
      });
    },
    restore(obj) {
      const temp = {
        Address: obj.address,
        Desc: obj.Desc,
        Initiators: obj.Initiators,
        Cover: obj.Cover,
        Name: obj.Name,
        Quota: obj.Quota,
        StartedAt: new Date(+obj.time[0]).toISOString(),
        EndedAt: new Date(+obj.time[1]).toISOString(),
        Type: this.ActivityType[obj.categoryId - 1],
      };

      return temp;
    },
    save(pane, index) {
      const data = pane.data;
      this.$refs[pane.key][0].validate((valid) => {
        if (valid) {
          const temp = this.restore(data);
          temp.Location = this.Location;
          if (data.id) {
            updateActivity(data.id, temp).then(() => {
              this.$message.success("保存成功");
            });
          } else {
            createActivity(temp).then(() => {
              this.$message.success("创建成功");
              this.panes.splice(index, 1);
              this.activityKey = "1";
              this.getList("1");
            });
          }
        }
      });
    },
    changeWeight(data, e) {
      this.$set(data, "weight", +e.target.value);
    },
    add1(data) {
      data.push({});
    },
    deleteCompitive(data, index) {
      data.splice(index, 1);
    },
    getActivityParticipant(id) {
      const params = {
        limit: 12,
        offset: (this.participantPage - 1) * 12,
      };
      getActivityParticipant(id, params)
        .then(({ data }) => {
          const rows = data.rows || [];
          this.participant = rows;
          this.participantCount = data.count;
        })
        .catch(() => {
          this.$message.error("获取报名名单失败");
          this.participant = [];
          this.participantCount = 0;
        });
    },
    getActivityCandidate(id) {
      const params = {
        limit: 12,
        offset: (this.candidatePage - 1) * 12,
      };
      getActivityCandidate(id, params)
        .then(({ data }) => {
          const rows = data.rows || [];
          this.candidate = rows;
          this.candidateCount = data.count;
        })
        .catch(() => {
          this.$message.error("获取报名名单失败");
          this.candidate = [];
          this.candidateCount = 0;
        });
    },
    getFileUrl(id) {
      return getFileUrl(id);
    },
    doClose(id) {
      this.current = id;
      this.visible = true;
    },
    close() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          closeActivity(this.current, this.model)
            .then(() => {
              this.$message.success("关闭成功");
              this.visible = false;
              this.model = {};
              this.remove(this.current);
              this.current = "";
            })
            .catch(() => {
              this.$message.error("关闭失败");
            });
        }
      });
    },
    changeTime(data, e) {
      const temp = { ...data };
      temp.StartedAt = new Date(+e[0]).toISOString();
      temp.EndedAt = new Date(+e[1]).toISOString();
      updateActivity(temp.id, temp).then(() => {
        this.$message.success("修改成功");
      });
    },
    validateLocation(rule, address, callback) {
      if (!address) {
        return callback();
      }
      getLocation({ address })
        .then(({ data }) => {
          this.Location = [data.Lat, data.Lng];
          callback();
        })
        .catch((err) => {
          callback(new Error("请输入详细地址"));
        });
    },
  },
};
</script>